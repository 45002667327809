<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>

<template>
  <v-card class="rounded-xl" :loading="loading" :disabled="loading">
    <v-card-title class="headline primary white--text">
      Create {{ getpatientN }}
    </v-card-title>
    <v-card-text class="pt-3">
      <v-form ref="patientForm" v-model="formValid">
        {{/* Datos personales */ }}
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="name">
              First Name <span class="red--text">*</span></label>
            <v-text-field v-model="form.firstName" :rules="[rules.required]" outlined dense />
          </v-col>
          <v-col cols="12" sm="4" class="px-1">
            <label class="font-weight-medium" for="midlename">
              Middle Name
            </label>
            <v-text-field v-model="form.midleName" outlined dense />
          </v-col>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="lastname">
              Last Name <span class="red--text">*</span></label>
            <v-text-field v-model="form.lastName" class="rounded" :rules="[rules.required]" outlined
              dense></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="ssn">SSN </label>
            <v-text-field v-model.number="form.ssn" outlined dense />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="dateofbirth">Date of Birth <span class="red--text">*</span>
            </label>
            <ma-date-picker :rules="[rules.required]" v-model="form.birthDate" past :editable="true" />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="gender">Gender <span class="red--text">*</span>
            </label>
            <v-select :items="genders" item-text="label" item-value="value" v-model="form.isMale" outlined
              dense></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label for="phone">Phone <span class="red--text">*</span> </label>
            <v-text-field v-model="homePhone" :rules="[rules.required, rules.phone]" prepend-inner-icon="mdi-phone"
              autocomplete="tel" outlined dense />
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="email">Email </label>
            <v-text-field v-model.number="form.email" :rules="[rules.email]" type="email" autocomplete="email"
              prepend-inner-icon="mdi-email" outlined dense />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="legal">Legal Status </label>
            <v-select :items="legalstatus" v-model="form.legalStatus" item-text="label" item-value="value" dense
              outlined></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="cernatur">ID Number </label>
            <br />

            <v-text-field outlined v-model="form.idNumber" dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="cernatur">Expiration Date (if apply)
            </label>
            <ma-date-picker v-model="form.expDate" past :editable="true" />
          </v-col>
          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="cernatur">Is Married?
            </label>
            <v-select :items="civilstatus" item-text="label" item-value="value" outlined dense
              v-model="form.isMaried"></v-select>
          </v-col>
        </v-row>

        {{/* state, city and zip */ }}

        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="address">Address </label>
            <v-text-field v-model="form.address.addressLine1" prepend-inner-icon="mdi-map-marker" dense
              autocomplete="street-address" outlined />
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="apto">Apto </label>
            <v-text-field v-model="form.address.addressLine2" dense autocomplete="apto-address" outlined />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="state">State </label>
            <v-autocomplete v-model="state" :items="states" item-text="name" item-value="id" :loading="loadingStates"
              autocomplete="off" outlined dense />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="city">City </label>
            <v-autocomplete :disabled="stateCities.length == 0" v-model.number="form.address.city" :items="stateCities"
              item-text="name" item-value="id" :loading="loadingCities" autocomplete="off" mandatory outlined dense />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="zip">Zip </label>
            <v-text-field v-model="form.address.zipCode" :rules="[rules.zip]" autocomplete="postal-code" outlined
              dense />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="address">Mailing Address (if different)
            </label>
            <v-text-field prepend-inner-icon="mdi-map-marker" v-model="form.mailAddress.addressLine1" dense
              autocomplete="street-mail" outlined />
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="apto">Apto </label>
            <v-text-field v-model="form.mailAddress.addressLine2" dense autocomplete="apto-mail" outlined />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="state">State </label>
            <v-autocomplete :items="states" v-model="state1" item-text="name" item-value="id" :loading="loadingStates"
              autocomplete="off" outlined dense />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="city">City </label>
            <v-autocomplete :disabled="stateCities1.length == 0" :items="stateCities1" item-text="name" item-value="id"
              :loading="loadingCities1" autocomplete="off" v-model="form.mailAddress.city" mandatory outlined dense />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="zip">Zip </label>
            <v-text-field v-model="form.mailAddress.zipCode" :rules="[rules.zip]" autocomplete="postal-code" outlined
              dense />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="4" class="cerofnat"><label class="font-weight-medium" for="cernatur">Employed or
              Self-Employed
            </label>
            <br />
            <label class="font-weight-medium" for="ifemp">(if Employed, Company Name)
            </label>
            <v-text-field outlined v-model="form.employedCompany" dense></v-text-field></v-col>
          <v-col cols="12" sm="3" class="px-1 pt-4 mt-2"><label class="font-weight-medium" for="workphone">Work Phone
            </label>
            <v-text-field prepend-inner-icon="mdi-phone" outlined :rules="[rules.phone1]" v-model="form.workPhone"
              dense></v-text-field></v-col>
          <v-col cols="12" sm="3" class="pr-1 pt-4 mt-2"><label class="font-weight-medium"
              for="posocc">Position/Occupation
            </label>
            <v-text-field v-model="form.ocupation" outlined dense></v-text-field></v-col>
          <v-col cols="12" sm="2" class="pt-4 mt-2"><label class="font-weight-medium" for="annualinco">Annual Income
            </label>
            <v-text-field v-model="form.anualIncome" outlined dense></v-text-field></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3"><label class="font-weight-medium" for="cprefer">Contact Preference
            </label>
            <v-select outlined dense v-model="form.contactPreference" :items="['PHONE', 'EMAIL']"></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1"><label class="font-weight-medium" for="preferlang">Preferred Language
            </label>
            <v-select outlined dense :items="langs" item-text="label" item-value="value"
              v-model="form.preferLanguage"></v-select>
          </v-col>
          <v-col cols="12" sm="3"><label class="font-weight-medium" for="source">Source <span class="red--text">*</span>
            </label>
            <v-select :items="references" :rules="[rules.required]" item-text="name" item-value="id"
              v-model="form.sourceId" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="pl-1"><label class="font-weight-medium" for="cernatur">NPN
            </label>
            <br />
            <v-text-field outlined v-model="form.npn" dense></v-text-field></v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source">Company <span class="red--text">*</span></label>
            <v-select :items="companies" item-text="name" :rules="[rules.required]" item-value="id"
              v-model="companySelect" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source">Company State <span class="red--text">*</span></label>
            <v-select :items="companyStates" :loading="loadingCompanyStates" item-text="state.name"
              :disabled="companySelect == null || companyStates.length == 0" :rules="[rules.required]"
              item-value="state.id" v-model="form.companyStateId" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source">Metal Plan </label>
            <v-select :items="metalplan" item-text="label" item-value="value" v-model="form.policyPlan" outlined
              dense></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source">Binder Pay </label>
            <v-radio-group v-model="form.binderDay" :rules="[rules.required]" class="mt-0" mandatory row dense
              hide-details>
              <v-radio :value="true" :label="$t('Yes')" />
              <v-radio :value="false" :label="$t('No')"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source">Police Number
            </label>
            <v-text-field v-model="form.polizeNumber" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source">Effective Date
            </label>
            <ma-date-picker v-model="form.effectiveDate" past :editable="true" />
          </v-col>

          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source">Members </label>
            <v-text-field v-model="form.members" outlined dense></v-text-field></v-col>
          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="tags">Tag </label>
            <v-select item-text="name" item-value="id" :items="tags" v-model="form.tagId" outlined dense></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-alert class="mx-4" type="error" :value="formValid == false || form.birthDate == '' || !validDate">
      {{
        !validDate
          ? "Invalid Date of Birth"
          : "All fields with ( * ) are required"
      }}
    </v-alert>
    <v-divider class="mb-3" />

    <v-card-actions class="justify-end">
      <v-btn color="error" :disabled="loading" width="100" dark depressed rounded @click="cancel">
        Cancel
      </v-btn>
      <!--      <v-btn
        color="grey"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="$refs.patientForm.reset()"
      >
        Reset
      </v-btn> -->
      <v-btn class="ml-3" color="primary" :disabled="loading || formValid == false || form.birthDate == ''"
        :loading="loading" width="100" depressed rounded @click="create">
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import Errors from "../PdfSign/Errors.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import City from "@/models/City";

import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifySuccess,
} from "@/components/Notification";
import rules from "@/components/account/rules";

import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { Lead } from "@/models/Lead";

import { ErrorCRM } from "@/models/Errors";
import moment from "moment";
import { cleanPhoneNumber } from "@/utils/formatPhone";

export default Vue.extend({
  components: { Errors, MaDatePicker },
  name: "formd-demographics",
  data() {
    return {
      formValid: false,
      birthDateMenu: false,
      loadingCompanyStates: false,
      companySelect: null,
      companyStates: [],

      rules: {
        required: rules.required,
        email: rules.emailnull,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      stateCities: [],
      loadingCities: false,
      state: 0,
      stateCities1: [],
      loadingCities1: false,
      state1: 0,

      loading: false,
      showErrors: false,
      errorMessage: "",

      /*  how it finded us
      how do i find us */
      civilstatus: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      legalstatus: [
        { label: "Citizen", value: "CITIZEN" },
        { label: "Permanent Resident", value: "PERMANENT_RESIDENT" },
        { label: "Temporal Resident", value: "TEMPORAL_RESIDENT" },
      ],
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      loadingmedical: false,
      year: "",
      month: "",
      homePhone: "",
      form: {
        contactPreference: "PHONE",
        firstName: "",
        lastName: "",
        midleName: "",
        birthDate: "",
        ssn: "",
        homePhone: "",
        preferLanguage: "ENGLISH",
        isMale: false,
        sourceId: 0,
        tagId: null,
        email: "",
        leadUuid: "",
        policyPlan: "BRONZE",
        effectiveDate: "",
        agentUuid: "",
        isMaried: false,
        legalStatus: "",
        binderDay: false,
        employedCompany: "",
        companyId: null,
        companyStateId: null,
        npn:null,
        workPhone: "",
        idNumber: "",
        members: 1,
        expDate: "",
        anualIncome: "",
        ocupation: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        },
        mailAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        },
      },
      formatNumber: (v) => /^[0-9]+$/.test(v) || this.$t("formatError"),
      validateYear: (v) => {
        if (v == null) {
          return "";
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const userYear = parseInt(v);

        if (v.length != 4) {
          return this.$t("lengthYearError");
        }

        if (userYear > currentYear) {
          return true;
        }

        return this.$t("yearWrong");
      },
      formatCvc: (v) =>
        /^[0-9][0-9]{2,3}$/.test(v) || this.$t("formatCvcError"),

      validateCard: (v) => {
        const isCard =
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

        return isCard.test(v) || this.$t("wrongCard") + "";
      },
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapState("crmConfigurationsModule", ["leadSelect"]),
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "references",
      "companies",
      "tags",
    ]),
    ...mapState(["profile"]),
    validDate() {
      if (this.form.birthDate == "" || this.form.birthDate == null) {
        return true;
      }
      const d = moment(this.form.birthDate);
      const now = moment();
      if (d.isBefore(now)) {
        return true;
      }
      return false;
    },
    months() {
      return [
        {
          value: 1,
          name: this.$t("January"),
        },
        {
          value: 2,
          name: this.$t("February"),
        },
        {
          value: 3,
          name: this.$t("March"),
        },
        {
          value: 4,
          name: this.$t("April"),
        },
        {
          value: 5,
          name: this.$t("May"),
        },
        {
          value: 6,
          name: this.$t("June"),
        },
        {
          value: 7,
          name: this.$t("July"),
        },
        {
          value: 8,
          name: this.$t("August"),
        },
        {
          value: 9,
          name: this.$t("September"),
        },
        {
          value: 10,
          name: this.$t("October"),
        },
        {
          value: 11,
          name: this.$t("November"),
        },
        {
          value: 12,
          name: this.$t("December"),
        },
      ];
    },

    validPhonePrimary() {
      const path = /^[1-9][0-9]{9}$/;
      if (path.test(this.form.homePhone)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    homePhone: {
      handler() {
        this.homePhone = cleanPhoneNumber(this.homePhone);
        if (this.homePhone[0] == "1") {
          this.homePhone = this.homePhone.slice(1);
        }
        this.form.homePhone = this.homePhone;
      },
    },
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
    state1: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities1 = [];
        this.loadingCities1 = false;

        return;
      }

      this.loadingCities1 = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities1 = false;
        this.stateCities1 = cities;
      });
    },
    companySelect(val) {
      if (val != null) {
        this.form.companyId = val;
        this.getCompanyState(val);
      }
    },
  },
  async mounted() {
    this.loadingmedical = true;

    if (this.leadSelect != null) {    
      this.form.firstName = this.leadSelect.name;
      this.form.birthDate = this.leadSelect.birthDate;
      this.form.lastName = this.leadSelect.last_name;
      this.form.email = this.leadSelect.email;
      this.form.policyPlan = this.leadSelect.policyPlan
        ? this.leadSelect.policyPlan
        : null;
      this.form.members = this.leadSelect.members ? this.leadSelect.members : 1;
      this.form.isMale = this.leadSelect.isMale;
      this.form.sourceId = this.leadSelect.sourceNom
        ? this.leadSelect.sourceNom.id
        : 0;
      this.form.tagId = this.leadSelect.tagNom
        ? this.leadSelect.tagNom.id
        : null;
      this.companySelect = this.leadSelect.company
        ? this.leadSelect.company.id
        : null;
      this.homePhone = this.leadSelect.phone.replace("+1", "");
      this.companySelect = this.leadSelect.company
        ? this.leadSelect.company.id
        : null;

      this.form = {
        ...this.form,
        quotedDate: this.leadSelect.quotedDate,
        premium: this.leadSelect.premium,
      };
    }
    if (localStorage.getItem("log-client")) {
      let lc = localStorage.getItem("log-client");
      lc = atob(lc);
      lc = JSON.parse(lc);
      this.form.firstName = lc.name;
      this.form.birthDate = lc.dob;
      this.form.lastName = lc.lastName;
    }
    await this.actListStates();

    this.state = 23;

    this.loadingmedical = false;
    this.actListReferences();
    this.actListTags();
    this.actListCompaniesNom();
    this.loading = true;
    //  await this.actGetEmployees();
    this.loading = false;
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",
      "actGetEducationLevels",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
      "actListTags",
    ]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    create() {
      let { form: body } = this;
      let { birthDate, ...rest } = body;
      let ISObirdDate = new Date(birthDate).toISOString();
      if (this.leadSelect != null) {
        rest.leadUuid = this.leadSelect.uuid;
      }
      if (rest.homePhone) {
        rest.homePhone = "+1" + rest.homePhone;
      }
      if (rest.workPhone) {
        rest.workPhone = "+1" + rest.workPhone;
      }
      if (rest.companyId) {
        rest.companyId = Number(rest.companyId);
      }
      if (rest.tagId) {
        rest.tagId = Number(rest.tagId);
      }
      if (rest.members) {
        rest.members = Number(rest.members);
      }
     
      if (rest.companyStateId) {
        rest.companyStateId = Number(rest.companyStateId);
      }
      if (rest.sourceId) {
        rest.sourceId = Number(rest.sourceId);
      }
      if (rest.sourceId == 0) {
        delete rest.sourceId;
      }

      rest.address.state = this.state.toString();

      rest.mailAddress.state = this.state1.toString();

      if (
        rest.mailAddress.addressLine1 == "" ||
        rest.mailAddress.addressLine1 == null ||
        rest.mailAddress.addressLine1 == undefined
      ) {
        delete rest.mailAddress;
      } else {
        rest.mailAddress.city = rest.mailAddress.city.toString();
      }
      if (
        rest.address.addressLine1 == "" ||
        rest.address.addressLine1 == null ||
        rest.address.addressLine1 == undefined
      ) {
        delete rest.address;
      } else {
        rest.address.city = rest.address.city.toString();
      }

      if (
        rest.leadUuid == null ||
        rest.leadUuid == undefined ||
        rest.leadUuid == ""
      ) {
        delete rest.leadUuid;
      }

      rest = this.cleanNull(rest);
      this.loading = true;

      getAPI
        .post("/patient/create", { ...rest, birthDate: ISObirdDate })
        .then((res) => {
          notifySuccess(this.$t("medicForm.notificationPatientCreated"));
          this.showErrors = false;
          this.mutleadSelect(null);
          this.$refs.patientForm.reset();
          this.loading = false;
          localStorage.setItem("tab-patient", "details");
          this.$router.push({
            name: "Patient ddetails",
            params: { uuid: res.data.uuid },
          });
        })
        .catch((error) => {
          this.loading = false;
          const errorResponse = error.response.data;
          let message = "Error: ";
          errorResponse.message.forEach((m) => {
            message = message + m + ", ";
          });
          message = message.substr(0, message.length - 1);
          notifyError(message);
        });
    },

    reset() {
      this.$refs.patientForm.reset();
    },
    cancel() {
      this.$refs.patientForm.reset();
      this.loading = false;
      this.$router.push({ name: "Patient list" });
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    getExpiration(month, year) {
      const date = moment(year);
      date.startOf("year").add(month - 1, "month");

      return date.toISOString();
    },
    getYears() {
      const yearsList = [];
      const currentYear = new Date().getFullYear();

      for (let i = 1; i <= 10; i++) {
        yearsList.push(currentYear + i);
      }

      return yearsList;
    },

    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  destroyed() {
    this.mutleadSelect(null);
    localStorage.removeItem("log-client");
  },
});
</script>
<style lang="scss" scoped>
.cerofnat {
  padding-top: 2px !important;
}
</style>

<i18n>
{
  "en": {
    "title": "Add a credit card",
    "cardNumber": "Credit card number",
    "cardHolder": "Card Holder",
    "expirationDate": "Expiration date",
    "cvc": "Card verification code (CVC)",
    "addCard": "Add card",
    "confirm": "Confirm",
    "year": "Year",
    "month": "Month",
    "formatError": "Wrong format, type a number",
    "yearWrong": "The year provided have to major to current year",
    "lengthYearError": "The year must have four characters",
    "wrongCard": "You must use one of the following cards: Visa, MasterCard, American Express, Diners Club, Discover or JCB",
    "formatCvcError": "Invalid CVC, only 3 or 4 digits are accepted",
    "cardSelected": "The card has been added, select it from the list",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "addOne": "Card Data",
    "epayaddOne": "USAePay Card Data"
  },
  "es": {
    "title": "Agregar tarjeta de crédito",
    "cardNumber": "Número de tarjeta de crédito",
    "expirationDate": "Fecha de expiración",
    "cvc": "CVC inválido, solo se aceptan 3 o 4 dígitos",
    "addCard": "Agregar tarjeta",
    "confirm": "Confirmar",
    "year": "Año",
    "month": "Mes",
    "formatError": "Formato erróneo, escriba un número",
    "yearWrong": "El año proporcionado debe ser mayor al año actual.",
    "lengthYearError": "El año debe tener cuatro caracteres",
    "wrongCard": "Debe usar una de las siguientes tarjetas: Visa, MasterCard, American Express, Diners Club, Discover o JCB",
    "formatCvcError": "CVC inválido",
    "cardSelected": "La tarjeta ha sido añadida, selecciónala de la lista",
    "January": "Enero",
    "February": "Febrero",
    "March": "Marzo",
    "April": "Abril",
    "May": "Mayo",
    "June": "Junio",
    "July": "Julio",
    "August": "Agosto",
    "September": "Septiebre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre",
    "addOne": "Datos de la tarjeta",
     "epayaddOne": "Datos de la tarjeta USAePay"
  }
}
</i18n>
